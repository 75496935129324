import moment from 'moment'
import React, { Children } from 'react'
import { useHistory } from 'react-router-dom'

export const ColumnIndex = ({ field }) => <React.Fragment />
export const ColumnText = ({ field }) => <React.Fragment />
export const ColumnDate = ({ field }) => <React.Fragment />
export const ColumnDateTime = ({ field }) => <React.Fragment />
export const ColumnUnixDateTime = ({ field }) => <React.Fragment />
export const ColumnDuration = ({ field }) => <React.Fragment />
export const ColumnYesNo = ({ field }) => <React.Fragment />
export const ColumnSize = ({ field }) => <React.Fragment />
export const ColumnSelector = ({ field }) => <React.Fragment />
export const ColumnLink = ({ field }) => <React.Fragment />
export const ColumnMenu = ({ menu }) => <React.Fragment />

export const getColumns = children => {
  let items = Children.toArray(children)

  let columns = items.map(item => {
    switch (item.type.name) {
      case ColumnIndex.name:
        return {
          field: 'Index',
          cellRenderer: row => {
            return (
              <span>
                {row.node.rowIndex}
              </span>
            )
          }
        }

      case ColumnText.name:
        return { field: item.props.field, hide: item.props.hide, headerName: item.props.header, suppressHeaderMenuButton: true }

      case ColumnDate.name:
        return {
          field: item.props.field,
          headerName: item.props.header,
          hide: item.props.hide,
          suppressHeaderMenuButton: true,
          cellRenderer: row => (row.value ? moment(row.value).format('MM/DD/YYYY') : '')
        }

      case ColumnDateTime.name:
        return {
          field: item.props.field,
          headerName: item.props.header,
          hide: item.props.hide,
          suppressHeaderMenuButton: true,
          cellRenderer: row => (row.value ? moment(row.value).format('MM/DD/YYYY : hh:mm:ss a') : '')
        }

      case ColumnUnixDateTime.name:
        return {
          field: item.props.field,
          headerName: item.props.header,
          hide: item.props.hide,
          suppressHeaderMenuButton: true,
          cellRenderer: row => (row.value ? moment(row.value).unix('MM/DD/YYYY : hh:mm:ss a') : '')
        }

      case ColumnDuration.name:
        let cellRendererDuration = row => {
          if (row.data) {
            let end = row.data[item.props.endedAt]
            let start = row.data[item.props.beganAt]
            if (end && start) {
              var diff = moment(end) - moment(start)
              var duration = moment.duration(diff).asMilliseconds()
              return moment.utc(duration).format('HH:mm:ss')
            }
          }

          return ''
        }

        return {
          field: item.props.field,
          headerName: item.props.header,
          hide: item.props.hide,
          suppressHeaderMenuButton: true,
          cellRenderer: cellRendererDuration
        }

      case ColumnYesNo.name:
        return {
          field: item.props.field,
          headerName: item.props.header,
          hide: item.props.hide,
          suppressHeaderMenuButton: true,
          cellRenderer: row => (row.value ? <span>Yes</span> : <span>No</span>)
        }

      case ColumnSize.name:
        return {
          field: item.props.field,
          headerName: item.props.header,
          hide: item.props.hide,
          suppressHeaderMenuButton: true,
          cellRenderer: row => formatSize(row.value)
        }

      case ColumnSelector.name:
        return {
          field: item.props.field,
          headerName: item.props.header,
          hide: item.props.hide,
          suppressHeaderMenuButton: true,
          cellRenderer: row => (item.props.selector && row.data ? item.props.selector(row.data) : ''),
          comparator: item.props.comparator
        }

      case ColumnLink.name:
        return {
          field: item.props.field,
          headerName: item.props.header,
          hide: item.props.hide,
          suppressHeaderMenuButton: true,
          cellRenderer: row => <Link text={row.data[item.props.field]} to={item.props.to(row.data)} />
        }

      case ColumnMenu.name:
        let cellRendererMenu = row => {
          if (row.data) {
            return (
              <div className="float-end d-flex h-100">
                {item.props.menu && item.props.menu(row.data)}
              </div>
            )
          }
          return ''
        }

        return {
          field: 'menu',
          headerName: '',
          cellRenderer: cellRendererMenu,
          sortable: false,
          pivot: false,
          enableRowGroup: false,
          suppressSizeToFit: true,
          resizable: false,
          suppressHeaderMenuButton: true
        }

      default:
        return {}
    }
  })

  return columns
}

const Link = ({ text, to }) => {
  const history = useHistory()
  const handleClick = () => history.push(to)
  return (
    <span className="text-decoration-underline" onClick={handleClick}>
      {text}
    </span>
  )
}

var bytes = 8
var kilabyte = 1024
var megabyte = kilabyte * 1024
var gigabyte = megabyte * 1024

const formatSize = value => {
  const getSize = value => {
    if (value >= gigabyte) {
      return `${(value / gigabyte).toFixed(2)} GB`
    } else if (value >= megabyte) {
      return `${(value / megabyte).toFixed(2)} MB`
    } else if (value >= kilabyte) {
      return `${(value / kilabyte).toFixed(2)} KB`
    } else {
      return `${(value / bytes).toFixed(2)} KB`
    }
  }

  return (
    <span>
      {getSize(value)}
    </span>
  )
}
