import React, { useMemo, useRef } from 'react'
import { useParamsInt } from '../../../../shared/hooks/location'
import { IconTable } from '../../../../shared/icon/icon'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { useLoad } from '../../../../shared/hooks/load'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { useApiListReportGeneralCourse } from '../../../../api-new/state/analytics'
import { Menu, MenuExportCsv, MenuExportExcel } from '../../../../shared/menu/menu'
import { useApiFormNode } from '../../../../api-new/state/content'
import { TableAgClient } from '../../../../shared/table-builder/table-ag-client'
import { ColumnDateTime, ColumnDuration, ColumnText } from '../../../../shared/table-builder/table-columns'

export const AnalyticCourseGeneral = () => {
  const { nodeID } = useParamsInt()

  const apiFormNode = useApiFormNode()
  const apiListReportGeneralCourse = useApiListReportGeneralCourse()

  useLoad(() => apiFormNode.get(nodeID), [])

  const handleReady = () => apiListReportGeneralCourse.get(`${nodeID}/node`)
  const handleDestroyed = () => apiListReportGeneralCourse.init()

  const title = useMemo(() => `Analytics General - ${apiFormNode.form.name}`, [apiFormNode.form])

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()

  const HeaderMenu = () =>
    <Menu>
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconTable} text="analytics general">
        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <TableAgClient ref={tableRef} apiList={apiListReportGeneralCourse} sortDesc onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnDateTime field="startOn" />
            <ColumnDateTime field="endOn" />

            <ColumnDuration field="duration" beganAt="startOn" endedAt="endOn" />

            <ColumnText field="email" />
            <ColumnText field="firstName" />
            <ColumnText field="lastName" />

            <ColumnText field="duration" />

            <ColumnText field="stepName" header="Step" />
            <ColumnText field="objectives" />
            <ColumnText field="objectivesCompleted" />
            <ColumnText field="attachments" />
            <ColumnText field="attachmentsCompleted" header="Attachments Viewed" />

            <ColumnText field="os" />
            <ColumnText field="platform" />
            <ColumnText field="appVersion" />
            <ColumnText field="deviceModel" />
            <ColumnText field="deviceType" />
            <ColumnText field="vrDeviceModel" />
            <ColumnText field="vrDeviceName" />
            <ColumnText field="vrDevicePresent" />
            <ColumnText field="vrDeviceTrackingOriginMode" />
            <ColumnText field="vrDeviceTrackingSpaceType" />
            <ColumnText field="vrDeviceRefreshRate" />
            <ColumnText field="graphicsCard" />
            <ColumnText field="graphicsCardMemorySize" />
            <ColumnText field="graphicsCardVendor" />
            <ColumnText field="processor" />
            <ColumnText field="processorCount" />
            <ColumnText field="processorFrequency" />
            <ColumnText field="systemMemorySize" />
            <ColumnText field="isMultiPlayer" />
            <ColumnText field="locationCountry" />
            <ColumnText field="locationRegion" />
            <ColumnText field="locationCity" />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
