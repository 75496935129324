import React, { useMemo } from 'react'
import { Card } from '../../../../../../shared/card/card'
import moment from 'moment'

export const Display = ({ children, title }) => {
  return (
    <Card title={title}>
      {children}
    </Card>
  )
}

export const Text = ({ label, value }) => {
  const show = useMemo(() => !!value, [value])

  return (
    <React.Fragment>
      {show &&
        <div className="p-2">
          <label className="form-label text-capitalize">
            {label}
          </label>
          <div className="form-control">
            {value}
          </div>
        </div>}
    </React.Fragment>
  )
}

export const TextArea = ({ label, value }) => {
  const show = useMemo(() => !!value, [value])

  return (
    <React.Fragment>
      {show &&
        <div className="p-2">
          <label className="form-label text-capitalize">
            {label}
          </label>
          <textarea className="form-control" value={value} rows="10" readOnly />
        </div>}
    </React.Fragment>
  )
}

export const DateTime = ({ label, value }) => {
  const show = useMemo(() => !!value, [value])

  return (
    <React.Fragment>
      {show &&
        <div className="p-2">
          <label className="form-label text-capitalize">
            {label}
          </label>
          <div className="form-control">
            {moment(value).format('MM/DD/YYYY hh:mm:ss a')}
          </div>
        </div>}
    </React.Fragment>
  )
}
