import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormatLabel } from '../format-label'
import { FormHiddenNumber } from '../form-hidden-number'
import { useGetValue } from '../hooks/input-hooks'

const accessData = [
  {
    id: 'null',
    name: 'Only assigned users or groups',
    displayOrder: 1
  },
  // {
  //   id: 1,
  //   name: 'Hidden',
  //   displayOrder: 2
  // },
  {
    id: 3,
    name: 'All users',
    displayOrder: 3
  },
  {
    id: 2,
    name: 'All users in this organization',
    displayOrder: 4
  }
]

export const FormCourseAccessID = ({ label, name, required }) => {
  const { setValue } = useFormContext()

  let value = useGetValue(name)

  const handleChange = e => setValue(name, e.target.value, { shouldDirty: true })

  const selectValue = useMemo(() => value || 0, [value])

  return (
    <React.Fragment>
      <FormatLabel name={name} label={label} hasValue={!!value} required={required}>
        <FormHiddenNumber name={name} value={value} />
        <select className="form-control text-capitalize" value={selectValue} onChange={handleChange}>
          {accessData &&
            accessData.map((item, i) =>
              <option key={i} value={item.id}>
                {item.name}
              </option>
            )}
        </select>
      </FormatLabel>
    </React.Fragment>
  )
}
