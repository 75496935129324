import React, { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormatLabel } from '../format-label'
import { IconClickAdd, IconClickDelete } from '../../icon/icon-click'
import { useGetValue } from '../hooks/input-hooks'

const Type = ({ value, submitting, onChange }) => {
  const handleChange = e => onChange(e.target.value)

  return (
    <React.Fragment>
      <select className="form-control" value={value} readOnly={submitting} onChange={handleChange}>
        <option value="string">Text</option>
        <option value="number">Number</option>
        <option value="boolean">Bool</option>
        <option value="date">Date</option>
        <option value="list">List</option>
      </select>
    </React.Fragment>
  )
}

const isInt = value => /^[0-9]+$/.test(value)
const isBool = value => value === true || value === false
const isDate = value => /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value)
const isList = value => value instanceof Array

const MetaKeyValue = ({ meta, submitting, onChange, onDelete }) => {
  const [type, updateType] = useState()
  const [showList, updateShowList] = useState('hide')

  useEffect(
    () => {
      let int = isInt(meta.value)
      let bool = isBool(meta.value)
      let date = isDate(meta.value)
      let list = isList(meta.value)

      if (int) {
        updateType('number')
      } else if (bool) {
        updateType('boolean')
      } else if (date) {
        updateType('date')
      } else if (list) {
        updateType('list')
      } else {
        updateType('string')
      }
    },
    [meta]
  )

  const handleChangeType = value => {
    updateType(value)
    if (value === 'list') {
      updateShowList('show')
    }
  }
  const handleChangeKey = e => onChange({ key: e.target.value, value: meta.value })
  const handleChangeValue = e => onChange({ key: meta.key, value: e.target.value })
  const handleChangeBoolean = e => onChange({ key: meta.key, value: e.target.value === 'true' })
  const handleChangeList = value => onChange({ key: meta.key, value: value })
  const handleShowList = e => updateShowList(e.target.value)

  return (
    <React.Fragment>
      <tr>
        <td>
          <input className="form-control" type="text" placeholder="Item Key" value={meta.key} readOnly={submitting} onChange={handleChangeKey} />
        </td>
        <td>
          <Type value={type} submitting={submitting} onChange={handleChangeType} />
        </td>
        <td>
          {type === 'string' &&
            <input className="form-control" type="text" placeholder="Item Value" value={meta.value} readOnly={submitting} onChange={handleChangeValue} />}
          {type === 'number' &&
            <input className="form-control" type="number" placeholder="Item Value" value={meta.value} readOnly={submitting} onChange={handleChangeValue} />}
          {type === 'boolean' &&
            <select className="form-control" value={meta.value} readOnly={submitting} onChange={handleChangeBoolean}>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>}
          {type === 'date' && <input className="form-control" type="date" value={meta.value} readOnly={submitting} onChange={handleChangeValue} />}
          {type === 'list' &&
            <select className="form-control" value={showList} readOnly={submitting} onChange={handleShowList}>
              <option value="hide">
                Hide List ({meta.value.length} values)
              </option>
              <option value="show">Show List</option>
            </select>}
        </td>
        <td>
          <IconClickDelete onClick={onDelete} />
        </td>
      </tr>
      {showList === 'show' &&
        type === 'list' &&
        <tr>
          <td colSpan={4}>
            <ListValue list={meta.value} submitting={submitting} onChange={handleChangeList} />
          </td>
        </tr>}
    </React.Fragment>
  )
}

const ListValue = ({ list, submitting, onChange }) => {
  let items = useMemo(() => (list instanceof Array ? list : []), [list])

  const handleChange = (value, i) => {
    let newList = [...items]
    newList[i] = value
    onChange(newList)
  }

  const handleAdd = () => onChange([...items, ''])

  const handleDelete = i => {
    let newList = [...items]
    newList.splice(i, 1)
    onChange(newList)
  }

  return (
    <React.Fragment>
      <table className="table table-borderless">
        <thead>
          <tr>
            <td>#</td>
            <td>List Values</td>
            <td>
              <IconClickAdd onClick={handleAdd} />
            </td>
          </tr>
        </thead>
        <tbody>
          {items.map((item, i) =>
            <React.Fragment>
              <tr>
                <td>
                  {i + 1}
                </td>
                <td>
                  <input className="form-control" placeholder="List Item" value={item} readOnly={submitting} onChange={e => handleChange(e.target.value, i)} />
                </td>
                <td>
                  <IconClickDelete onClick={() => handleDelete(i)} />
                </td>
              </tr>
            </React.Fragment>
          )}
        </tbody>
      </table>
    </React.Fragment>
  )
}

export const FormMetadata = ({ label, name }) => {
  const { setValue } = useFormContext()

  let value = useGetValue(name)

  const metadata = useMemo(() => (value ? JSON.parse(value) : null), [value])
  const metaKeyValueList = useMemo(() => (metadata ? Object.entries(metadata).map(value => ({ key: value[0], value: value[1] })) : []), [metadata])

  const handleChange = (i, meta) => {
    metaKeyValueList[i] = meta
    let metaString = metaKeyValueList.reduce((obj, item) => ({ ...obj, [item.key]: item.value }), {})
    let metadata = JSON.stringify(metaString)
    setValue(name, metadata, { shouldDirty: true })
  }

  const handleAdd = () => {
    let newList = [...metaKeyValueList, { key: '', value: '' }]
    let metaString = newList.reduce((obj, item) => ({ ...obj, [item.key]: item.value }), {})
    let metadata = JSON.stringify(metaString)
    setValue(name, metadata, { shouldDirty: true })
  }

  const handleDelete = i => {
    let newList = [...metaKeyValueList]
    newList.splice(i, 1)
    let metaString = newList.reduce((obj, item) => ({ ...obj, [item.key]: item.value }), {})
    let metadata = JSON.stringify(metaString)
    setValue(name, metadata, { shouldDirty: true })
  }

  return (
    <React.Fragment>
      <FormatLabel name={name} label={label} hasValue={!!value}>
        <table className="table">
          <thead>
            <tr>
              <th>Key</th>
              <th>Type</th>
              <th>Value</th>
              <th>
                <IconClickAdd onClick={handleAdd} />
              </th>
            </tr>
          </thead>
          <tbody>
            {metaKeyValueList.map((meta, i) =>
              <MetaKeyValue key={i} meta={meta} submitting={false} onChange={value => handleChange(i, value)} onDelete={() => handleDelete(i)} />
            )}
          </tbody>
        </table>
      </FormatLabel>
    </React.Fragment>
  )
}
