import { cloneElement } from 'react'
import { FloatingPortal, offset, useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react'

export const Floating = ({ children, content, placement, isOpen, onOpenChange }) => {
  const { context, refs, floatingStyles } = useFloating({
    placement: placement || 'left',
    middleware: [
      offset(({ placement, x, y, rects }) => {
        let bottom = y + rects.floating.height

        if (y < 0) {
          let adj = Math.abs(y) + 30
          return { mainAxis: 0, crossAxis: adj }
        }

        if (bottom + 50 > window.innerHeight) {
          let offset = window.innerHeight - bottom - 30
          return { mainAxis: 0, crossAxis: offset }
        }

        return { mainAxis: 0, crossAxis: 0 }
      })
    ],
    open: isOpen,
    onOpenChange: onOpenChange
  })

  const click = useClick(context)

  useDismiss(context, {
    enabled: true,
    escapeKey: true,
    outsidePress: true
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([click])

  return (
    <div>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>
      {isOpen &&
        <FloatingPortal>
          <div ref={refs.setFloating} style={{ ...floatingStyles, zIndex: 1000 }} {...getFloatingProps()}>
            {content && cloneElement(content, {})}
          </div>
        </FloatingPortal>}
    </div>
  )
}
