import React, { useMemo, useRef } from 'react'
import { useParamsInt } from '../../../shared/hooks/location'
import { useApiFormUserLogin } from '../../../api-new/state/content'
import { useApiListReportGeneralUser } from '../../../api-new/state/analytics'
import { useLoad } from '../../../shared/hooks/load'
import { Menu, MenuExportCsv, MenuExportExcel } from '../../../shared/menu/menu'
import { BreadcrumbView } from '../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../shared/card/card-full-height-scroll-y'
import { IconTable } from '../../../shared/icon/icon'
import { TableAgClient } from '../../../shared/table-builder/table-ag-client'
import { ColumnDateTime, ColumnDuration, ColumnText } from '../../../shared/table-builder/table-columns'

export const AnalyticUserGeneral = () => {
  const { userID } = useParamsInt()

  const apiFormUserLogin = useApiFormUserLogin()
  const apiListReportGeneralUser = useApiListReportGeneralUser()

  useLoad(() => apiFormUserLogin.get(userID), [])

  const handleReady = () => apiListReportGeneralUser.get(`${userID}/user`)
  const handleDestroyed = () => apiListReportGeneralUser.init()

  const title = useMemo(() => `Analytics General - ${apiFormUserLogin.form.email}`, [apiFormUserLogin.form])

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()

  const HeaderMenu = () =>
    <Menu>
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconTable} text="analytics general">
        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <TableAgClient ref={tableRef} apiList={apiListReportGeneralUser} onReady={handleReady} onDestoryed={handleDestroyed}>
            <ColumnText field="nodeName" header="Course" />
            <ColumnText field="groupName" header="Group" />
            <ColumnDateTime field="startOn" />
            <ColumnDateTime field="endOn" />
            <ColumnDuration field="duration" beganAt="startOn" endedAt="endOn" />
            <ColumnText field="stepName" header="Step" />
            <ColumnText field="objectives" />
            <ColumnText field="objectivesCompleted" />
            <ColumnText field="attachments" />
            <ColumnText field="attachmentsCompleted" header="Attachments Viewed" />

            <ColumnText field="os" />
            <ColumnText field="platform" />
            <ColumnText field="appVersion" />
            <ColumnText field="deviceModel" />
            <ColumnText field="deviceType" />
            <ColumnText field="vrDeviceModel" />
            <ColumnText field="vrDeviceName" />
            <ColumnText field="vrDevicePresent" />
            <ColumnText field="vrDeviceTrackingOriginMode" />
            <ColumnText field="vrDeviceTrackingSpaceType" />
            <ColumnText field="vrDeviceRefreshRate" />
            <ColumnText field="graphicsCard" />
            <ColumnText field="graphicsCardMemorySize" />
            <ColumnText field="graphicsCardVendor" />
            <ColumnText field="processor" />
            <ColumnText field="processorCount" />
            <ColumnText field="processorFrequency" />
            <ColumnText field="systemMemorySize" />
            <ColumnText field="isMultiPlayer" />
            <ColumnText field="locationCountry" />
            <ColumnText field="locationRegion" />
            <ColumnText field="locationCity" />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
