import React from 'react'
import { IconAnalyticGeneral } from '../../../shared/icon/icon'
import { BreadcrumbView } from '../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../shared/card/card-full-height-scroll-y'
import { useApiListReportGeneralOrg } from '../../../api-new/state/analytics'
import { ColumnDateTime, ColumnDuration, ColumnText } from '../../../shared/table-builder/table-columns'
import { TableAgInfinite } from '../../../shared/table-builder/table-ag-infinite'

export const AnalyticOrgGeneral = () => {
  const apiListReportGeneralOrg = useApiListReportGeneralOrg()

  const handelGetRows = async (startRow, endRow, search, sortCol, sortDir) =>
    await apiListReportGeneralOrg.get(`range/${startRow}/${endRow}?search=${search}&sortCol=${sortCol}&sortDir=${sortDir}`)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAnalyticGeneral} text="ANALYTICS GENERAL">
        <CardFullHeightScrollY title="General Analytics">
          <TableAgInfinite apiList={apiListReportGeneralOrg} onGetRows={handelGetRows}>
            <ColumnDateTime field="startOn" />
            <ColumnDateTime field="endOn" />
            <ColumnDuration field="duration" beganAt="startOn" endedAt="endOn" />

            <ColumnText field="sessionID" />

            <ColumnText field="nodeName" header="Course" />

            <ColumnText field="email" />
            <ColumnText field="firstName" />
            <ColumnText field="lastName" />

            <ColumnText field="stepName" header="Step" />
            <ColumnText field="objectives" />
            <ColumnText field="objectivesCompleted" />
            <ColumnText field="attachments" />
            <ColumnText field="attachmentsCompleted" header="Attachments Viewed" />

            <ColumnText field="os" />
            <ColumnText field="platform" />
            <ColumnText field="appVersion" />
            <ColumnText field="deviceModel" />
            <ColumnText field="deviceType" />
            <ColumnText field="vrDeviceModel" />
            <ColumnText field="vrDeviceName" />
            <ColumnText field="vrDevicePresent" />
            <ColumnText field="vrDeviceTrackingOriginMode" />
            <ColumnText field="vrDeviceTrackingSpaceType" />
            <ColumnText field="vrDeviceRefreshRate" />
            <ColumnText field="graphicsCard" />
            <ColumnText field="graphicsCardMemorySize" />
            <ColumnText field="graphicsCardVendor" />
            <ColumnText field="processor" />
            <ColumnText field="processorCount" />
            <ColumnText field="processorFrequency" />
            <ColumnText field="systemMemorySize" />
            <ColumnText field="isMultiPlayer" />
            <ColumnText field="locationCountry" />
            <ColumnText field="locationRegion" />
            <ColumnText field="locationCity" />

            <ColumnText field="groups" />
          </TableAgInfinite>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
