import React, { useMemo } from 'react'
import { SketchPicker } from 'react-color'
import { FormatLabel } from '../format-label'
import { useFormContext } from 'react-hook-form'
import { Popup } from '../../legacy/popup'
import { useFormDisable } from '../form'
import { useFormRequired, useGetValue } from '../hooks/input-hooks'
import { IconTimes, IconColorPallet } from '../../icon/icon'

export function FormColor({ label, name, placeholder, required }) {
  const { setValue } = useFormContext()

  let value = useGetValue(name)

  let formDisabled = useFormDisable()

  useFormRequired(name, required)

  const updateValue = value => setValue(name, value, { shouldDirty: true })

  const handleChange = e => updateValue(e.currentTarget.value)
  const handleChangePicker = value => updateValue(value.hex)
  const handleClear = () => updateValue('')

  const test = useMemo(() => value || '', [value])

  return (
    <React.Fragment>
      <FormatLabel name={name} label={label} hasValue={!!value} required={required}>
        <div className="input-group">
          <div className="input-group-text">
            <Popup selector={<IconColorPallet className="p-0" />}>
              <SketchPicker color={value} onChangeComplete={handleChangePicker} />
            </Popup>
          </div>

          <div className="input-group-text" style={{ backgroundColor: value || 'white', width: '35px' }}>
            <span />
          </div>

          {/* <input name={name} {...register(name, { required })} hidden readOnly /> */}

          <input
            className="form-control"
            value={test}
            onChange={handleChange}
            type="text"
            disabled={formDisabled}
            placeholder={placeholder || `enter ${label}`}
          />

          <div className="input-group-text" onClick={handleClear}>
            <span className="text-capitalize">
              <IconTimes className="p-0" />
            </span>
          </div>
        </div>
      </FormatLabel>
    </React.Fragment>
  )
}
