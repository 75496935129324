import React from 'react'
import { useApiFormChunkErrorLog } from '../../../../../../api-new/state/content'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Menu, MenuItem } from '../../../../../../shared/menu/menu'
import { useHistory } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { DateTime, Display, Text, TextArea } from '../display/display'
import { IconEdit } from '../../../../../../shared/icon/icon'

export function LogErrorDetails() {
  const history = useHistory()
  const { logErrorID } = useParamsInt()

  const apiFormChunkErrorLog = useApiFormChunkErrorLog()

  const handlePath = async () => await apiFormChunkErrorLog.get(logErrorID)

  const handleDelete = async () => {
    await apiFormChunkErrorLog.delete(logErrorID)
    history.goBack()
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuItem text="delete" onClick={handleDelete} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="edit" onPath={handlePath}>
        <CardFullHeightScrollY title="error" HeaderMenu={HeaderMenu}>
          <Display title="details">
            <DateTime label="occured" value={apiFormChunkErrorLog.form.createdAt} />
            <Text label="organization" value={apiFormChunkErrorLog.form.organization} />
            <Text label="email" value={apiFormChunkErrorLog.form.email} />
            <Text label="message" value={apiFormChunkErrorLog.form.message} />
            <Text label="method" value={apiFormChunkErrorLog.form.method} />
            <Text label="statusCode" value={apiFormChunkErrorLog.form.statusCode} />
            <Text label="host" value={apiFormChunkErrorLog.form.host} />
            <Text label="query" value={apiFormChunkErrorLog.form.query} />
            <Text label="remoteAddress" value={apiFormChunkErrorLog.form.remoteAddress} />
            <TextArea label="headers" value={apiFormChunkErrorLog.form.headers} />
            <TextArea label="body" value={apiFormChunkErrorLog.form.body} />
            <TextArea label="stack" value={apiFormChunkErrorLog.form.stack} />
          </Display>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
